import { distinctUntilChanged, map } from 'rxjs/operators';
import { Component, OnInit, PLATFORM_ID, effect, inject, signal } from '@angular/core';
import { filter, Subject, Subscription, switchMap, tap, Unsubscribable } from 'rxjs';
import { UserService } from '@origin8-web/core-ui/login/providers';
import { Store } from '@ngrx/store';
import { EnableNotificationBannerComponent, NotificationService } from '@origin8-web/core-ui/notification';
import { priorityLeadsActions } from '../core/store/actions/priority-leads.actions';
import { BrowserExtApiService } from '@origin8-web/core-ui/browser-ext-api';
import { UserStore } from '../core/store/user/user.store';
import { WarmTransferStore } from '../core/store/wt/warm-transfer.store';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './navbar/navbar.component';
import { ImpersonationWidgetComponent } from '@origin8-web/core-ui/login/components';
import { LiveStatusStore } from '../core/store/live-status/live-status.store';
import { isPlatformBrowser } from '@angular/common';
import { PROTEC8_PATH } from '../app.interface';
import { withoutStartingSlash } from '@origin8-web/o8-utils/format';

@Component({
  selector: 'p8-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NavbarComponent, EnableNotificationBannerComponent, ImpersonationWidgetComponent],
})
export class AppComponent implements OnInit {
  readonly userStore = inject(UserStore);
  readonly wtStore = inject(WarmTransferStore);
  readonly liveStatusStore = inject(LiveStatusStore);
  readonly userService = inject(UserService);
  readonly store = inject(Store);
  readonly notificationService = inject(NotificationService);
  readonly browserExtApiService = inject(BrowserExtApiService);
  readonly platformId = inject(PLATFORM_ID);
  readonly router = inject(Router);

  unsubscribe$ = new Subject<void>();
  auth$!: Subscription;
  authNotification$!: Subscription;
  hasInit = false;
  hideNavbar = signal<boolean>(false);

  loadUpcomingUserAppointmentsSub: Unsubscribable | undefined;
  upcomingAppointmentNotificationSub: Unsubscribable | undefined;
  flashPageTitleOnIncomingTransfersSub: Unsubscribable | undefined;
  wtAdvocateAvailabilityCheckSub: Unsubscribable | undefined;
  cleanUpExpiredWarmTransferEventsSub: Unsubscribable | undefined;
  getWTEventsSub: Unsubscribable | undefined;
  getWTPickedUpEventsSub: Unsubscribable | undefined;
  notifyWtSub: Unsubscribable | undefined;

  constructor() {
    effect((onCleanup) => {
      this.loadUpcomingUserAppointmentsSub?.unsubscribe();
      this.userStore.loadUpcomingUserAppointments(this.userStore.userInfo());
      onCleanup(() => {
        this.loadUpcomingUserAppointmentsSub?.unsubscribe();
      });
    });
    effect((onCleanup) => {
      this.upcomingAppointmentNotificationSub?.unsubscribe();
      this.userStore.upcomingAppointmentNotification(this.userStore.nextAppointment());
      onCleanup(() => {
        this.upcomingAppointmentNotificationSub?.unsubscribe();
      });
    });
    effect((onCleanup) => {
      this.flashPageTitleOnIncomingTransfersSub?.unsubscribe();
      this.wtStore.flashPageTitleOnIncomingTransfers(this.wtStore.incomingWarmTransferAvailableForAdvocate());
      onCleanup(() => {
        this.flashPageTitleOnIncomingTransfersSub?.unsubscribe();
      });
    });
    effect((onCleanUp) => {
      this.cleanUpExpiredWarmTransferEventsSub?.unsubscribe();
      this.wtStore.cleanUpExpiredWarmTransferEvents(this.wtStore.active());
      onCleanUp(() => {
        this.cleanUpExpiredWarmTransferEventsSub?.unsubscribe();
      });
    });
    effect((onCleanup) => {
      this.getWTEventsSub?.unsubscribe();
      this.wtStore.getWTEvents(this.wtStore.active());
      onCleanup(() => {
        this.getWTEventsSub?.unsubscribe();
      });
    });
    effect((onCleanup) => {
      this.getWTPickedUpEventsSub?.unsubscribe();
      this.wtStore.getWtePickedUpEvents(this.wtStore.active());
      onCleanup(() => {
        this.getWTPickedUpEventsSub?.unsubscribe();
      });
    });
    effect(
      (onCleanup) => {
        this.notifyWtSub?.unsubscribe();
        this.wtStore.notifyUserForIncomingTransfers(this.wtStore.unNotifiedInboundCalls());
        onCleanup(() => {
          this.notifyWtSub?.unsubscribe();
        });
      },
      { allowSignalWrites: true },
    );
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.url),
        distinctUntilChanged(),
        tap((url) => {
          this.hideNavbar.set(withoutStartingSlash(url) === PROTEC8_PATH.INCOMING_WARM_TRANSFER);
        }),
      )
      .subscribe();
    this.userService
      .getIsLoggedIn$()
      .pipe(
        filter((loggedIn) => !!loggedIn),
        switchMap(() => this.userService.getSelfOrImpersonatedUser$()),
        distinctUntilChanged(),
        tap((userInfo) => {
          this.userStore.setUserInfo(userInfo);
          this.initiateBackgroundEffects();
        }),
      )
      .subscribe();
    this.notificationService.requestPermission();
    this.browserExtApiService.checkExtensionIsInstalledAndDisplayErrorMessageIfNot().subscribe();
    if (isPlatformBrowser(this.platformId)) {
      this.liveStatusStore.loadLiveStatuses();
      this.liveStatusStore.loadRepresentativesProfiles();
    }
  }

  initiateBackgroundEffects() {
    if (!this.hasInit) {
      this.wtStore.setActiveState(true);
      this.store.dispatch(priorityLeadsActions.setActiveState({ active: true }));
      this.hasInit = true;
    }
  }
}
