import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { environment } from '../environments/environment';
import { TrackerModule } from '@origin8-web/core-ui/tracking';
import { APP_CONFIG, localStorageProvider, windowProvider } from '@origin8-web/core-ui/shared-providers';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withPreloading,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { StoreRouterConnectingModule, MinimalRouterStateSerializer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { withApolloGqlProviders } from '@origin8-web/core-ui/graphql';
import { logoutReducer } from '@origin8-web/core-ui/login/ngrx';
import { topPriorityWorkStoreKey } from '../app.interface';
import { priorityLeadsReducers } from '../core/store/reducers/priority-leads.reducer';
import { provideServiceWorker } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { withLoginProviders } from '@origin8-web/core-ui/login/providers';
import { PriorityLeadsEffects } from '../core/store/effects/priority-leads/priority-leads.effects';
import { provideBootstrapEffects } from '@origin8-web/core-ui/ngrx';
import { Protec8Env } from '../environments';
import { provideLottieOptions, provideCacheableAnimationLoader } from 'ngx-lottie';

export const appConfig: ApplicationConfig = {
  providers: [
    withLoginProviders(),
    withApolloGqlProviders(),
    importProvidersFrom(
      TrackerModule.forRoot({ anonymous: false, withGtm: false }),
      StoreModule.forRoot(
        {
          [topPriorityWorkStoreKey]: priorityLeadsReducers,
        },
        { metaReducers: [logoutReducer] },
      ),
      StoreRouterConnectingModule.forRoot({
        serializer: MinimalRouterStateSerializer,
      }),

      EffectsModule.forRoot([]) /* Always leave this empty and use provideBootstrapEffects below instead*/,
    ),
    localStorageProvider,
    windowProvider,
    provideHttpClient(withFetch()),
    provideAnimationsAsync(),
    provideBootstrapEffects([PriorityLeadsEffects]),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withComponentInputBinding(),
      withPreloading(PreloadAllModules),
    ),
    provideLottieOptions({
      player: () => import('lottie-web/build/player/lottie_svg'),
    }),
    provideCacheableAnimationLoader(),
  ],
};
export const getAppConfig = (envVariables: Protec8Env) => {
  return { ...appConfig, providers: [...appConfig.providers, { provide: APP_CONFIG, useValue: envVariables }] };
};
